html {
  min-height: -webkit-fill-available;
}

/* *************************** */
/* Typography */

@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: 'Hiatus';
  font-weight: 300;
  font-style: normal;
  src: url(./fonts/Hiatus.woff) format('woff');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 300;
  font-style: normal;
  src: url(./fonts/FoundersGrotesk-Light.woff) format('woff');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 500;
  font-style: normal;
  src: url(./fonts/FoundersGrotesk-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Canela';
  font-weight: 500;
  font-style: normal;
  src: url(./fonts/Canela-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Canela';
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/Canela-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'FuturaLT';
  font-weight: 500;
  font-style: normal;
  src: url(./fonts/FuturaLT.woff2) format('woff2');
}

@font-face {
  font-family: 'FuturaLT';
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/FuturaLT-Bold.woff2) format('woff2');
}

/* *************************** */
/* Base Styles */

body {
  /* color: #284135; */
  color: #c02163;
  font-family: 'Inter var', sans-serif;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
}

canvas {
  animation: fade-in 0.5s ease 0.3s forwards;
  animation-delay: 0s;
  height: 100vh;
  opacity: 0;
  touch-action: none;
  user-select: none;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.canvas-guide {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  bottom: 60px;
  color: #fafafa;
  font-family: 'FoundersGrotesk';
  font-size: 12px;
  font-weight: 300;
  left: 50%;
  margin: 0 auto;
  max-width: 200px;
  opacity: 0.6;
  padding: 12px 12px 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 25vw;
}

.canvas-guide p {
  font-size: 12px;
}

div.line {
  margin: 16px auto 4px;
  width: 64px;
  height: 2px;
  background: #e46298;
  text-align: center;
}

.content-left {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 64px;
  width: 100%;
  z-index: 2;
}

.content-right {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 36px;
  top: 72px;
}

h3,
p {
  font-family: 'FoundersGrotesk';
  font-weight: 300;
}

.registry-button {
  border: none;
  border-radius: 4px;
  background-color: #24a2cf !important;
  color: #fafafa;
  font-family: 'FoundersGrotesk';
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.rsvp-button {
  align-items: center;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 4px darkslategray;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #c02163 !important;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  font-family: 'FoundersGrotesk';
  font-size: 14px;
  font-weight: 400;
  gap: 6px;
  justify-content: space-between;
  letter-spacing: 2px;
  margin: 0 !important;
  margin-left: 12px;
  padding: 18px !important;
  /* width: 60px; */
  /* height: 60px; */
}

.circle-button {
  background-color: rgba(0, 0, 0, 0) !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  border: none;
  padding: 0;
  text-decoration: none;
  text-align: center;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  outline: none;
  transition: 1s ease all;
  margin-right: 0.25rem;
}

.content-right .rsvp-button,
.content-right .circle-button {
  margin: 0 0 14px 0 !important;
}

.circle-button p {
  font-size: 12px;
  /* font-family: 'Canela'; */
  font-family: 'FoundersGrotesk';
  font-weight: 300;
  margin: 0;
  letter-spacing: 2px;
  line-height: 4px;
}

.cirlce-button svg {
  stroke: white;
}

.button-container {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding: 0 12px 16px;
  z-index: 1;
}

.button-container p {
  margin: -8px;
}

.content-body {
  color: #284135;
  display: block;
}

h1 {
  font-family: 'Canela';
  font-size: 48px;
  line-height: 48px;
  font-weight: 300;
  margin: 0;
  color: #284135;
  letter-spacing: -2px;
  text-align: left;
}

h1.handwritten {
  color: #de3f82;
  font-family: 'Hiatus';
  font-size: 96px;
  line-height: 44px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 3px;
  text-align: center;
  user-select: none;
}

h1.handwritten span {
  font-size: 48px;
  opacity: 0.5;
}

@media (max-width: 550px) {
  h1.handwritten {
    font-size: 48px;
  }

  .modal-wrapper {
    height: 100vh !important;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    margin: 0 !important;
    min-width: 80vw !important;
  }
}

@media (min-width: 551px) {
  h1.handwritten {
    font-size: 72px;
  }

  .modal-wrapper {
    max-width: 50vw !important;
  }
}

h1 > span {
  white-space: pre;
}

h2 {
  font-family: 'Canela';
  font-weight: 300;
}

.section {
  color: #284135;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.section h1,
.section h2 {
  font-family: 'Canela';
  font-size: 48px;
  line-height: 48px;
  font-weight: 300;
  margin: 1em 0;
}

.section p {
  font-family: 'FoundersGrotesk';
  font-size: 24px;
}

.section .subheading {
  font-family: 'FoundersGrotesk';
  font-size: 24px;
  font-weight: 300;
}

/* *************************** */
/* Modal */

[data-reach-dialog-overlay] {
  background: none !important;
  z-index: 2;
}

[data-reach-dialog-content] {
  background: rgba(0, 0, 0, 0) !important;
  border: none !important;
  border-radius: 16px !important;
  color: black;
  max-width: 525px;
  padding: 0 !important;
  width: 100vw !important;
}

@media (max-width: 600px) {
  [data-reach-dialog-content] {
    max-width: 90%;
  }
}

.modal-content {
  padding: 24px;
  position: relative;
  z-index: 2;
}

.modal-title {
  color: rgb(202, 43, 99);
  font-size: 26px;
  margin-bottom: 12px;
  margin-top: 12px;
  text-align: center;
}

.modal-content p {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.modal-content button {
  font-family: 'FoundersGrotesk';
  letter-spacing: 3px;
}

.modal-content a {
  box-shadow: 0px 2px 0px rgb(202, 43, 99);
  color: rgb(202, 43, 99);
  font-family: 'FoundersGrotesk';
  font-weight: 300;
  text-decoration: none;
}

.modal-content .button-link {
  box-shadow: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.modal-content a:hover {
  box-shadow: 0px 3px 0px rgb(200, 43, 99);
}

.modal-content-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  -webkit-tap-highlight-color: transparent;
}

.modal-close-btn:focus {
  outline: none;
}

.modal-close-btn img {
  vertical-align: middle;
}

/* *************************** */
/* Button */

button {
  outline: none;
}

button:focus-visible {
  outline: revert;
}

.filter-blur {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 10;
  -webkit-backdrop-filter: saturate(90%) blur(36px);
  backdrop-filter: saturate(90%) blur(36px);
}

.text-input {
  margin: 0 12px 12px 0px;
  font-size: 14px;
  padding: 8px;
  border-radius: 6px;
  border: none;
}

.rsvp-invitee-row {
  display: flex;
  flex-direction: row;
}

.event-details p {
  color: #284135;
  font-family: 'FoundersGrotesk';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 0;
  text-align: center;
}

.event-details {
  margin: 24px 0 6px 0;
}

/* *************************** */
/* Button */

.pushable {
  background: hsl(330deg 65% 35%);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: hsl(339deg 65% 48%);
  color: white;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.front {
  will-change: transform;
  transition: transform 250ms;
}

.pushable:hover .front {
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.pushable {
  transition: filter 600ms;
}
.pushable:hover {
  transition: filter 250ms;
  filter: brightness(110%);
}
.pushable span {
  font-family: 'FoundersGrotesk';
  font-weight: 300;
}

/* *************************** */
/* Footer */

footer {
  bottom: 12px;
  font-family: 'FoundersGrotesk';
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  text-align: center;
  width: 100%;
}

.footer-text {
  font-family: 'FoundersGrotesk';
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

/* *************************** */
/* Utilities */

.invisible {
  visibility: hidden;
}

a[href~="https://app3.rsvpify.com/register"]
{
  display: none !important;
}

.rsvp-form {
  background-color: rgba(0, 0, 0, 0) !important;
}

.modal-wrapper {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(24px);
  margin: 10vh auto;
  outline: none;
  background: transparent !important;
  border: none !important;
  border-radius: 16px !important;
  color: #000;
  max-width: 525px;
  padding: 0 !important;
  width: 100vw !important;
}

/* .modal-content {
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  height: 75%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

button {
  height: 32px;
  min-width: 100px;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: white;
  border: 1px solid transparent;
  border-bottom: 3px solid #a0a0a0;
  border-radius: 3px;
  padding: 8px 24px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: auto;
  height: 45px;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.stack {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #606060;
}

.bg {
  background: #252525;
}

.fullscreen .logout {
  background: transparent;
  border: none;
  width: auto;
  color: white;
  display: block;
  margin: 10px auto;
}

.fullscreen .continue-link {
  text-decoration: none;
  transition: all 1s;
  width: 20%;
  text-align: center;
}

.fullscreen.notready .continue-link {
  cursor: auto;
  color: #606060;
}

.fullscreen.ready .continue-link {
  cursor: pointer;
  color: white;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(192, 33, 99);
  background: linear-gradient(
    180deg,
    rgba(192, 33, 99, 1) 0%,
    rgba(138, 119, 135, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 8s;
  z-index: 1;
  font-family: 'Almendra', serif;
}

.loadingScreen__board {
  border-radius: 16px;
  padding: 64px;
  text-align: center;
  transition: opacity 0.4s;
}

.loadingScreen__title {
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  margin-bottom: 8px;
}

.loadingScreen__button {
  height: 32px;
  min-width: 100px;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: white;
  border: 1px solid transparent;
  border-bottom: 3px solid #a0a0a0;
  border-radius: 3px;
  padding: 8px 24px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: auto;
  height: 45px;
}

.loadingScreen__button:hover {
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.loadingScreen__button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.loadingScreen__progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
}

.loadingScreen__progress__value {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
  transition: width 0.4s;
}

.loadingScreen--started {
  opacity: 0;
  pointer-events: none;
}

.loadingScreen--started .loadingScreen__board {
  opacity: 0;
}
